/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"


const Nav = () => {
  return (
    <div className="nav">
      <ul class="topNav">
        <li class="topNavItem"><Link to="/">Home</Link></li>
        <li class="topNavItem"><Link to="/about">About</Link></li>
        <li class="topNavItem"><Link to="/books">Books</Link></li>
        <li class="topNavItem"><Link to="/projects">Personal Projects</Link></li>
        <li class="topNavItem"><Link to="/contact">Contact</Link></li>
      </ul>
 
    </div>
  )
}
export default Nav
